<template>
    <div class="container fluid">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">

                <div class="card elevation-5">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-2">
                                <v-menu
                                    ref="modal"
                                    v-model="startPeriodModal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        dense
                                        v-model="startPeriodModel"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        label="Start Period"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="startPeriodModel"
                                        type="month"
                                        no-title
                                        @input="startPeriodModal = false"
                                    ></v-date-picker>
                                </v-menu>
                            </div>
                            <div class="col-lg-2">
                                <v-menu
                                    ref="modal"
                                    v-model="endPeriodModal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        dense
                                        v-model="endPeriodModel"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        label="End Period"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="endPeriodModel"
                                        type="month"
                                        no-title
                                        @input="endPeriodModal = false"
                                    ></v-date-picker>
                                </v-menu>
                            </div>
                            <div class="col-lg-8">
                                <v-btn class="mt-3" color="primary" elevation="2" small rounded @click="getData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-lg-12 divResult">

                <div class="card elevation-5">
                    <div class="card-body">
                        <div id="chartContainer1" style="height: 350px; width: 100%;"></div>
                    </div>
                </div>

            </div>

        </div>

    </div>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'Sunrise Mill',
                disabled: false,
                href: '/admin/sm',
                },
                {
                text: 'Delivery',
                disabled: false,
                href: '#',
                },
                {
                text: 'Delivery Report Analysis',
                disabled: true,
                href: '#',
                },
            ],
            prodBrandModel: '',
            prodBrandLists: [],
            orderTypeModel: '',
            orderTypeLists: [
                {
                    value:'',
                    text:''
                },
                {
                    value:'FAA',
                    text:'FAA'
                },
                {
                    value:'SLS',
                    text:'SLS'
                }
            ],
            custModel: '',
            custLists: [
                {
                    value:'',
                    text:''
                },
                {
                    value:'SR',
                    text:'SUNRISE STEEL'
                },
                {
                    value:'SM',
                    text:'SUNRISE MILL'
                }
            ],
            commodityModel: '',
            commodityLists: [],
            monthLists: [
                {
                    value: '1',
                    text: 'JANUARY'
                },
                {
                    value: '2',
                    text: 'FEBRUARY'
                },
                {
                    value: '3',
                    text: 'MARCH'
                },
                {
                    value: '4',
                    text: 'APRIL'
                },
                {
                    value: '5',
                    text: 'MAY'
                },
                {
                    value: '6',
                    text: 'JUNE'
                },
                {
                    value: '7',
                    text: 'JULY'
                },
                {
                    value: '8',
                    text: 'AUGUST'
                },
                {
                    value: '9',
                    text: 'SEPTEMBER'
                },
                {
                    value: '10',
                    text: 'OCTOBER'
                },
                {
                    value: '11',
                    text: 'NOVEMBER'
                },
                {
                    value: '12',
                    text: 'DECEMBER'
                },
            ],
            monthModel: '',
            yearModel: '',
            yearLists: [
                {
                    value: '2020',
                    text: '2020'
                },
                {
                    value: '2021',
                    text: '2021'
                },
                {
                    value: '2022',
                    text: '2022'
                },
                {
                    value: '2023',
                    text: '2023'
                },
                {
                    value: '2024',
                    text: '2024'
                }
            ],
            startPeriodModal: false,
            startPeriodModel: null,
            startPeriodLists: [],
            endPeriodModal: false,
            endPeriodModel: null,
            endPeriodLists: []
        }
    },
    async mounted(){
        
        this.initialize()
        $('.divResult').hide()

    },
    methods:{
        
        async initialize(){

            this.$store.dispatch('setOverlay', true)
            
            await axios.get(`${process.env.VUE_APP_URL}/api/sm/DeliveryReportAnalysis`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.$store.dispatch('setOverlay', false)

                this.prodBrandLists = res.data.brand
                this.commodityLists = res.data.commodity
                this.startPeriodLists = res.data.period
                this.endPeriodLists = res.data.period

            })
            .catch(err => {
                
                this.$store.dispatch('setOverlay', false)
                console.log(err);
                
            })

        },

        async getData() {

            if(this.startPeriodModel == null && this.endPeriodModel == null){
                Swal.fire({
                    text: 'Please Select at least start or end period',
                    icon: 'warning',
                })
                return false
            }

            this.$store.dispatch('setOverlay', true)

            var startPeriod = this.startPeriodModel ? new Date(this.startPeriodModel).toISOString().substr(0, 7).replace(/-/g,"") : ''
            var endPeriod = this.endPeriodModel ? new Date(this.endPeriodModel).toISOString().substr(0, 7).replace(/-/g,"") : ''

            await axios.post(`${process.env.VUE_APP_URL}/api/sm/DeliveryReportAnalysis/getData`, { 
                startPeriod: startPeriod,
                endPeriod: endPeriod
            },
            {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
            })
            .then(res => {

                $('.divResult').show()
                this.renderChart(res.data.arr_list1, res.data.arr_list2, res.data.arr_list3, res.data.arr_list4)
                this.$store.dispatch('setOverlay', false)

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                console.log(err);
                

            })
            

        },

        renderChart(data1, data2, data3, data4){

            var chart = new CanvasJS.Chart("chartContainer1", {
                animationEnabled: true,
                theme: "light2",
                exportEnabled: true,
                title:{
                    text: "Commodity Delivery Analysis"
                },
                // subtitles: [{
                //     text: "Click for details",		
                //     fontColor: "grey",
                // }],
                axisX:{
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true,
                    },
                },
                axisY: {
                    title: "TON",
                    includeZero: true,
                    titleFontSize: 24,
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    gridColor: "#005f73"
                },
                legend: {
                    fontColor: "red",
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                toolTip:{
                    shared:true
                },
                data: [
                {
                    type: "stackedColumn",
                    legendText: "CRC",
                    name: 'CRC',
                    showInLegend: "true",
                    // indexLabel: "{y}",
                    yValueFormatString: "###,###.##",
                    indexLabelPlacement: "outside",
                    dataPoints: data1
                },
                {
                    type: "stackedColumn",
                    legendText: "HRC",
                    name: 'HRC',
                    showInLegend: "true",
                    // indexLabel: "{y}",
                    yValueFormatString: "###,###.##",
                    indexLabelPlacement: "outside",
                    dataPoints: data2
                },
                {
                    type: "stackedColumn",
                    legendText: "HRP",
                    name: 'HRP',
                    showInLegend: "true",
                    // indexLabel: "{y}",
                    yValueFormatString: "###,###.##",
                    indexLabelPlacement: "outside",
                    dataPoints: data3
                },
                {
                    type: "stackedColumn",
                    legendText: "HRPO",
                    name: 'HRPO',
                    showInLegend: "true",
                    // indexLabel: "{y}",
                    indexLabel: "#total T",
                    yValueFormatString: "###,###.##",
                    indexLabelPlacement: "outside",
                    dataPoints: data4
                },
            ]})

            chart.render()

        },

        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },

    },
    watch: {

        
    }
    
}
</script>

<style scoped>

    .v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }

    .modal {
        margin: 0 auto; 
    }

</style>